/*------------------------------------------------*/
/*--------- App Styles ----------*/
/*------------------------------------------------*/

body {
  margin: 0px;
  font-family: 'Nunito', sans-serif;
}

.hidden {
  display: none;
}

/*------------------------------------------------*/
/*--------- Blog ----------*/
/*------------------------------------------------*/

.post-date, .author {
  color: #AFB5B9 !important;
  font-size: 0.8rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.post-title {
  color: #FED766;
  text-decoration: none;
}

.post-title:hover {
  cursor: pointer;
  text-decoration: underline;
}

/*------------------------------------------------*/
/*--------- Disabled Slider ----------*/
/*------------------------------------------------*/

.MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0) !important;
    cursor: auto;
}

.MuiSlider-track:hover, .MuiSlider-rail:hover {
  cursor: auto !important;
}

.MuiSlider-root, .MuiSlider-colorPrimary, .MuiSlider-marked {
  cursor: auto !important;
}

/*------------------------------------------------*/
/*--------- Logo ----------*/
/*------------------------------------------------*/

.logo {
  font-weight: 900;
  font-size: 1.8rem;
  border-bottom: 5px double #FED766;
  padding-right: 2rem;
}

.toe-image-logo {
  max-height: 2rem;
}

.gary-image {
  max-height: 2rem;
  max-width: 2rem;
}

.gary-container {
  max-height: 2rem;
  overflow-y: visible;
  padding-top: 0.5rem;
}

.gary-title {
  padding-left: 0.8rem;
  border-left: 4px solid #FED766;
  border-bottom-left-radius: 0.1rem;
  border-top-left-radius: 0.1rem;
}

.gary-tooltip-box {
  background-color: #475C85;
  font-size: 0.8rem;
  color: white;
  padding: 0.2rem 1rem 0.2rem 1rem;
  border-radius: 0.6rem;
  width: inherit;
}

.gary-tooltip-box a, .large-banner-container a {
  color: white;
  text-decoration: underline;
  cursor: pointer
}

.large-banner-container {
  font-size: 0.8rem;
  background-color: #475C85;
  color: white;
  border-radius: 0.5rem;
}

.large-banner-text {

}

/*------------------------------------------------*/
/*--------- SVG Icon Fill and Sizing ----------*/
/*------------------------------------------------*/

.svg-light-gray {
  color: #C4C4C4;
}

.svg-light-gray:hover {
  color: #a7a7a7;
  cursor: pointer;
}

.svg-light-blue {
  color: #12AAEB;
}

.svg-light-blue:hover {
  color: #35b8f0;
  cursor: pointer;
}

.svg-white {
  color: #FFFFFF;
}

.svg-complete {
  color: #4FBF7E;
}

.svg-delete:hover {
  color: #B00020;
  cursor: pointer;
}

.svg-small {
  height: 12px;
  width: 12px;
}

.svg-home {
  width: 15rem;
}

.dashboard-project-svg {
  max-width: 12rem;
}

.share-modal {
  min-width: 45%;
}

.select-image-button {
  height: 10rem;
  border: 1px solid #AFB5B9;
  border-radius: 0.5rem;
  padding-top: 1rem;
  margin: 1rem;
  cursor: pointer;
}

.select-image-button:hover {
  background: #e7f6fd;
}

.select-image-button.selected {
  background: #12AAEB;
  color: white;
  border: 1px solid #475C85;
}

.select-image-button.selected:hover {
  background: #1099d3;
}

/*------------------------------------------------*/
/*--------- Text Decoration & Classes ----------*/
/*------------------------------------------------*/

.underline-on-hover:hover > * {
  text-decoration: underline;
  cursor: pointer;
}

.question-subtext {
  color: #AFB5B9;
  font-size: 0.8rem;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.question-disabled {
  color: #AFB5B9;
}

.error-text {
  color: red;
  font-size: 0.7rem;
}

.warning-box {
  border-radius: 0.3rem;
  border: 1px solid red;
}

.subsection-container {
  font-size: 0.8rem;
  padding-left: 0rem;
  padding-top: 0.2rem;
  padding-right: 0rem;
  cursor: pointer;
}

.subsection-container.green-fill {
  color: black;
  border-top: 4px solid #4FBF7E;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.subsection-container.green-fill:hover {
  border-top: 4px solid #4FBF7E;
  font-weight: bold;
}

.subsection-container.yellow-fill {
  color: black;
  border-top: 4px solid #FED766;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.subsection-container.yellow-fill:hover {
  border-top: 4px solid #fecd3f;
  font-weight: bold;
}

.subsection-container.gray-fill {
  color: #AFB5B9;
  border-top: 4px solid #E1E5E6;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.subsection-container.gray-fill:hover {
  border-top: 4px solid #ccd2d4;
  font-weight: bold;
}

.subsection-text-container {
  padding: 0.2rem;
}

.subsection-text-container.active {
  background-color: rgba(18, 170, 235, 0.1);
  padding-left: 0.4rem;
  border-radius: 0.4rem;
}

.table-header-bottom {
  align-self: flex-end;
  font-weight: bold;
}

.summary-stat-container {
  align-content: center;
  border: 0.3px solid #E1E5E6;
  border-radius: 0.8rem;
  margin: 0.2rem;
  padding: 0.5rem;
}

.table-center {
  align-self: flex-e;
}

.chart-title-row {
  font-weight: bold;
}

.progress-bar-container {
  background: #e1e5eb;
  height: 2.5rem;
  width: 100%;
}

.progress-bar-element {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  color: white;
  font-weight: bold;
}

.chart-label {
  padding-left: 0rem;
  padding-right: 0.2rem;
  padding-top: 0.2rem;
  font-size: 0.7rem;
}

/*------------------------------------------------*/
/*--------- Font Colors and Weights ----------*/
/*------------------------------------------------*/

.white, .font-white {
  color: white;
}

.light {
  font-weight: 200;
}

.size-80 {
  font-size: 0.8rem;
}

.gray {
  color: #AFB5B9;
}

.red {
  color: red;
}

/*------------------------------------------------*/
/*--------- Button and Background Colors ----------*/
/*------------------------------------------------*/

.light-blue {
  background: #12AAEB;
  color: white;
  border: none;
}

.light-blue:disabled,
.light-blut[disabled] {
  background-color: #cccccc;
  cursor: not-allowed;
}

.light-blue:hover:enabled {
  background: #35b8f0;
  cursor: pointer;
}

.royal-blue {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #12AAEB;
  color: white;
  border: none;
}

.royal-blue:hover:enabled {
  background: #0c75a2;
  cursor: pointer;
}

.dark-blue {
  background: #475C85;
  color: white;
  border: none;
}

.dark-blue:hover:enabled {
  background: #5e77a9;
  cursor: pointer;
}

.white-gray-border {
  background: #FFFFFF;
  border: 0.05rem solid #AFB5B9;
}

.white-gray-border:hover:enabled {
  background: #f5f5f5;
  border: 0.05rem solid #AFB5B9;
  cursor: pointer;
}

.white-no-border {
  background: #FFFFFF;
  border: none
}

.white-no-border:hover:enabled {
  background: #f5f5f5;
  cursor: pointer;
}

.gray-gray-border {
  background: #E1E5E6;
  border: 0.05rem solid #AFB5B9;
}

.gray-gray-border:hover:enabled {
  background: #ccd2d4;
  border: 0.05rem solid #AFB5B9;
  cursor: pointer;
}

.gray-no-border {
  background: #E1E5E6;
  border: none;
}

.gray-no-border:hover:enabled {
  background: #ccd2d4;
  border: none;
  cursor: pointer;
}

.green-no-border {
  background: #4FBF7E;
  color: white;
  border: none;
}

.green-no-border:hover:enabled {
  background: #3f9864;
  border: none;
  cursor: pointer;
}

.red-no-border {
  background: #ff0000;
  color: white;
  border: none;
}

.red-no-border:disabled,
.red-no-border[disabled] {
  background-color: #cccccc;
  cursor: not-allowed;
}


.red-no-border:hover:enabled {
  background: #ff0000;
  border: none;
  cursor: pointer;
}

/*------------------------------------------------*/
/*--------- BUTTONS ----------*/
/*------------------------------------------------*/


.button-small {
  border-radius: 0.3rem;;
  max-width: 5rem;
  min-width: 5rem;
  font-family: inherit;
}

.button-small:disabled {
  background: #E1E5E6;
  cursor: not-allowed;
}

.button-normal {
  border-radius: 0.3rem;;
  max-width: 10rem;
  min-width: 10rem;
  font-family: inherit;
}

.button-75 {
  border-radius: 0.3rem;;
  max-width: 75%;
  min-width: 75%;
  font-family: inherit;
}

.button-fill {
  border-radius: 0.3rem;;
  max-width: 100%;
  min-width: 100%;
  font-family: inherit;
}

.button-icon {
  text-align: left;
}

/*------------------------------------------------*/
/*--------- Navbar ----------*/
/*------------------------------------------------*/

.navbar {
  background: #FFFFFF;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  z-index: 1;
}

.navbar-link {
  color: #AFB5B9;
  text-decoration: none;
}

.navbar-link:hover {
  color: #90989e;
  text-decoration: underline;
}

.navbar-user-organization {
  font-size: 18px;
  line-height: 25px;
}

.navbar-user-name {
  color: #AFB5B9;
  font-size: 14px;
}

.navbar-user-dropdown {
  margin-top: 2.8rem;
}

.footer {
  background: #AFB5B9;
  color: white;
  font-size: 0.8rem;
}

.footer-tgp-logo {
  max-height: 3rem;
  padding-top: 1rem;
}

/*------------------------------------------------*/
/*--------- Login and Signup ----------*/
/*------------------------------------------------*/

.white-vertical-container {
  height: 95vh; /* less than 100vh so navbar never fully disappears */
  background: white;
}

.gray-vertical-container {
  height: 95vh; /* less than 100vh so navbar never fully disappears */
  background: #E1E5E6;
}

.homepage-logo {
  font-weight: 900;
  border-bottom: 5px double #FED766;
  padding-right: 2rem;
}

/*------------------------------------------------*/
/*--------- Proforma Page Structure ----------*/
/*------------------------------------------------*/

.proforma-list-container {
  padding: 0.5rem;
  border: 2px solid #AFB5B9;
  border-radius: 0.8rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.proforma-left-bar {
  margin-left: 0rem;
  margin-right: 0rem;
  padding: 0.8rem;
  background: #AFB5B9;
  height: 95vh; /* less than 100vh so navbar never fully disappears */
  overflow-y: scroll;
  z-index: 0;
}

.proforma-main-content {
  max-height: 95vh;  /* less than 100vh so navbar never fully disappears */
  overflow-y: scroll;
  z-index: 0;
}

.proforma-section-label {
  color: white;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  padding-top: 1rem;
  padding-bottom: 0.6rem;
}

.proforma-section-label:hover {
  cursor: pointer;
  background: rgba(15, 15, 15, 0.1);
}

.proforma-section-label.active {
  background: rgba(15, 15, 15, 0.3);
}

.section-name {
  padding-bottom: 0.2rem;
}

.total-box {
  background-color: #E1E5E6;
  border: 2px solid #AFB5B9;
  box-sizing: border-box;
  border-radius: 4px;
  font-weight: bold;
}

.div-bottom {
  align-self: flex-end;
}

.div-middle {
  align-self: center;
}

.irr-stat-wrapper {
  display: flex;
  flex-direction: row;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.irr-stat-wrapper.border {
  border: 0.05rem solid #AFB5B9;
  border-radius: 0.5rem;
}

.resource-wrapper, .flex-stretch {
  display: flex;
  align-items: stretch;
}

.resource-container {
  border: 0.05rem solid #AFB5B9;
  border-radius: 0.5rem;
  padding: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.resource-title {
  font-size: 0.9rem;
  color: black;
  font-weight: bold;
  margin-bottom: 0.1rem;
}

.resource-description {
  font-size: 0.7rem;
  color: #AFB5B9;
  margin-top: 0rem;
}

.resource-container a {
  text-decoration: none;
  color: #475C85;
  font-size: 0.7rem;
}

.resource-container a:hover {
  text-decoration: underline;
  cursor: pointer;
}

/*------------------------------------------------*/
/*--------- Output Table ----------*/
/*------------------------------------------------*/

.outputs-table-container {
  border: 1px solid #AFB5B9;
}

.output-section-title {
  font-weight: bold;
  overflow-x: hidden;
  white-space: nowrap;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
}

.first-column {
  position: sticky;
  left: 0;
  font-size: 0.7rem;
}

.first-column > div {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.second-column {
  overflow: auto;
}

.data-row {
  display: flex;
  flex-direction: row;
}

.data-row-label {
  border-top: 0.4px solid #e3e5e7;
  border-bottom: 0.4px solid #e3e5e7;
  padding-left: 1rem;
}

.data-row-label div {
  overflow-x: hidden;
  white-space: nowrap;
}

.data-row-label.row-section-break {
  color: black !important;
  border-bottom: 1px solid black;
  padding-top: 1.2rem !important;
  border-right: none !important;
  border-left: none !important;
}

.row-normal {
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
}

.row-bold {
  font-weight: bold;
  color: black;
}

.row-italic {
  font-style: italic;
  color: rgba(0, 0, 0, 0.6);
}

.row-section-break {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  color: white;
  border-bottom: 1px solid black !important;
  border-right: none !important;
  border-left: none !important;
  padding-top: 1.2rem !important;
}

.data-container {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-right: 0.25rem;
  font-size: 0.7rem;
  overflow-x: hidden;
  white-space: nowrap;
}

.data-container.border {
  border: 0.4px solid #e3e5e7;
}


/*------------------------------------------------*/
/*--------- CARDS ----------*/
/*------------------------------------------------*/


.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.card-title {
  font-size: 1.4rem;
  width: 100%;
}

.card-title:hover {
  font-weight: bold;
  cursor: pointer;
}

.card-body {
  font-weight: lighter;
}

.card-body a {
  color: #475C85;
  text-decoration: none;
  font-weight: bold;
}

.card-body a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.loading-div {
  background-color: #AFB5B9;
  height: 100%;
  width: 100%;
  border-radius: 1rem;
}

/*------------------------------------------------*/
/*--------- BORDERS ----------*/
/*------------------------------------------------*/

.border-right-gray {
  border-right: 0.1rem solid #AFB5B9;
}

.border-bottom-gray {
  border-bottom: 0.1rem solid #AFB5B9;
}

.black-underline {
  border-bottom: 0.1rem solid #000000;
}

.black-underline-light {
  border-bottom: 0.03rem solid #000000;
}

.black-underline-top {
  border-top: 0.1rem solid #000000;
}

.light-blue-border {
  border: 0.3rem solid #12AAEB !important;
}

/*------------------------------------------------*/
/*--------- SPACERS -- PADDING & MARGIN ----------*/
/*------------------------------------------------*/

/* || Margin */

.margin-none {
  margin: 0rem;
}

.margin-xxsmall {
  margin: 0.5rem;
}

.margin-xsmall {
  margin: 1rem;
}

.margin-small {
  margin: 2rem;
}

.margin-medium {
  margin: 4rem;
}

.margin-large {
  margin: 6rem;
}

.margin-bottom-neg {
  margin-bottom: -0.3rem;
}

.margin-bottom-none {
  margin-bottom: 0rem;
}

.margin-bottom-xxsmall {
  margin-bottom: 0.5rem;
}

.margin-bottom-xsmall {
  margin-bottom: 1rem;
}

.margin-bottom-small {
  margin-bottom: 2rem;
}

.margin-bottom-medium {
  margin-bottom: 4rem;
}

.margin-bottom-large {
  margin-bottom: 6rem;
}

.margin-right-xxsmall {
  margin-right: 0.5rem;
}

.margin-right-xsmall {
  margin-right: 1rem;
}

.margin-right-small {
  margin-right: 2rem;
}

.margin-right-medium {
  margin-right: 4rem;
}

.margin-right-large {
  margin-right: 6rem;
}

.margin-left-xxsmall {
  margin-left: 0.5rem;
}

.margin-left-xsmall {
  margin-left: 1rem;
}

.margin-left-small {
  margin-left: 2rem;
}

.margin-left-medium {
  margin-left: 4rem;
}

.margin-left-large {
  margin-left: 6rem;
}

.margin-top-none {
  margin-top: 0rem;
}

.margin-top-xxsmall {
  margin-top: 0.5rem;
}

.margin-top-xsmall {
  margin-top: 1rem;
}

.margin-top-small {
  margin-top: 2rem;
}

.margin-top-medium {
  margin-top: 4rem;
}

.margin-top-large {
  margin-top: 6rem;
}

/* || Padding */

.padding-none {
  padding: 0rem;
}

.padding-xxxsmall {
  padding: 0.25rem;
}

.padding-xxsmall {
  padding: 0.5rem;
}

.padding-xsmall {
  padding: 1rem;
}

.padding-small {
  padding: 2rem;
}

.padding-medium {
  padding: 4rem;
}

.padding-large {
  padding: 6rem;
}

.padding-bottom-xxsmall {
  padding-bottom: 0.5rem;
}

.padding-bottom-xsmall {
  padding-bottom: 1rem;
}

.padding-bottom-small {
  padding-bottom: 2rem;
}

.padding-bottom-medium {
  padding-bottom: 4rem;
}

.padding-bottom-large {
  padding-bottom: 6rem;
}

.padding-right-none {
  padding-right: 0rem;
}

.padding-right-xxsmall {
  padding-right: 0.5rem;
}

.padding-right-xsmall {
  padding-right: 1rem;
}

.padding-right-small {
  padding-right: 2rem;
}

.padding-right-medium {
  padding-right: 4rem;
}

.padding-right-large {
  padding-right: 6rem;
}

.padding-left-none {
  padding-left: 0rem;
}

.padding-left-xxsmall {
  padding-left: 0.5rem;
}

.padding-left-xsmall {
  padding-left: 1rem;
}

.padding-left-small {
  padding-left: 2rem;
}

.padding-left-medium {
  padding-left: 4rem;
}

.padding-left-large {
  padding-left: 6rem;
}

.padding-top-xxsmall {
  padding-top: 0.5rem;
}

.padding-top-xsmall {
  padding-top: 1rem;
}

.padding-top-small {
  padding-top: 2rem;
}

.padding-top-medium {
  padding-top: 4rem;
}

.padding-top-large {
  padding-top: 6rem;
}
